<template>
  <ModalForm
    v-model="formData"
    :title="title"
    class="modal--title-reset"
    text-btn-confirm="Сохранить"
    @request="onRequest"
    @reject="onReject"
  >
    <VSelect
      label="Колонки"
      class="mb-30"
      name="columns"
      item-text="title"
      multiple
      :options="tableColumnsDefault"
      show-number-selected
    >
    </VSelect>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'

import { mixinModal } from '@/mixins/modal/mixinModal'

import VSelect from '@/components/Form/VSelect.vue'
import { mapGetters } from 'vuex'
import UserController from '@/controllers/user/UserController'

export default {
  name: 'ModalConsignmentSettingsTable',
  components: {
    VSelect,
    ModalForm
  },
  props: {
    title: {
      type: String,
      default: 'Настройки'
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableColumnsCurrent: {
      type: [Object, Array],
      default: null
    }
  },
  data () {
    return {
      formData: {
        columns: this.tableColumnsCurrent
      }
    }
  },
  mixins: [mixinModal],

  computed: {
    ...mapGetters({
      tableColumnsDefault: 'consignment/tableColumnsDefault'
    })
  },

  methods: {
    async onRequest (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        let params = {}

        if (data?.columns) {
          const fields = []

          data.columns.forEach(function (item) {
            fields.push(item.name)
          })

         params = {
            meta: {
              views: {
                consignments: fields
              }
            }
          }
        }

        const response = await UserController.saveUserMeta(params)
        if (response?.status !== 200) return

        this.$store.commit('SET_USER_META', response?.data?.meta ?? null)
        this.onReject()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
