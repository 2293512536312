import { mixinCurrentRouteQueryPage } from '@/mixins/router/mixinCurrentRouteQueryPage'

// В компоненте должен быть метод loadPage и CLEAR_STATE
const mixinRegisterListPage = {
  ...mixinCurrentRouteQueryPage,

  watch: {
    $route () {
      this.loadPage(this.currentRouteQueryPage)
    }
  },

  async mounted () {
    await this.loadPage(this.currentRouteQueryPage)
    await this.$store.dispatch('scroll/scrollToNode')
  },
  destroyed () {
    this.CLEAR_STATE()
  }
}

export {
  mixinRegisterListPage
}
