import { setCurrentRouteQueryPage } from '@/utils/router'

const mixinCurrentRouteQueryPage = {
  computed: {
    currentRouteQueryPage () {
      return +this.$route.query.page || 1
    }
  },
  methods: {
    setCurrentRouteQueryPage
  }
}

export {
  mixinCurrentRouteQueryPage
}
