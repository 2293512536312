<template>
  <Spinner v-if="isLoadingConsignmentList"/>

  <section v-else>
    <h2 v-if="!consignmentList.length">
      Партий нет
    </h2>

    <div v-else>
      <div class="between ai-c mb-30">
        <h2>Партии</h2>
        <div class="flex gap-s">
          <VButton
            v-if="permissionExportXslConsignment"
            text="Экспорт в XLS"
            icon="export"
            size="sm"
            @click.native="getExcelFile"
            :loading="isLoadingExcel"
          />
          <VButton
            text="Настройки"
            size="sm"
            :icon="{ name: 'filter', width: '18px', height: '18px' }"
            @click.native="openModal('filter-setting')"
          />
          <div class="flex gap-xxs">
            <VButton
              size="sm"
              class="btn--no-shadow"
              icon="viewtype-cards"
              :active="viewType === 'cards'"
              @click.native="changeViewType()"
            />
            <VButton
              size="sm"
              class="btn--no-shadow"
              icon="viewtype-table"
              :active="viewType === 'table'"
              @click.native="changeViewType('table')"
            />
          </div>
        </div>
      </div>

      <div class="consignment-card-list" v-if="viewType === 'cards'">
        <ConsignmentCard
          v-for="consignment in consignmentList"
          :key="consignment.id"
          :consignment="consignment"
          @goStageNext="consignmentGoStageNextHandler"
          @goSplitPrint="consignmentGoSplitPrintHandler"
          @remove="consignmentRemoveHandler"
          @update="consignmentUpdateHandler"
          @addDirtyWeight="consignmentAddDirtyWeightHandler"
          @addExecutor="consignmentAddExecutorHandler"
        />
      </div>

      <div class="consignment-card-table" v-else>
        <ConsignmentTable
          :columns="tableColumnsCurrent"
          :items="consignmentList"
          :loading="loadingTable"
          class="table_consignment"
          rowActive
          @sorted="sortedHandler"
          @goStageNext="consignmentGoStageNextHandler"
          @goSplitPrint="consignmentGoSplitPrintHandler"
          @remove="consignmentRemoveHandler"
          @update="consignmentUpdateHandler"
          @addExecutor="consignmentAddExecutorHandler"
          @resetSort="resetSortHandler"
        />
      </div>

      <AppPagination
        v-if="pagination.pageCount > 1"
        :current-page="pagination.page"
        :page-count="pagination.pageCount"
        @setPage="setCurrentRouteQueryPage"
      />
    </div>

    <ModalConsignmentSettingsTable
      v-if="isModal === 'filter-setting'"
      class="modal-consignment-settings"
      :table-columns-current="tableColumnsCurrent"
      @reject="closeModal"
    />
  </section>
</template>

<script>
import AppPagination from '@/components/ui/AppPagination'
import ConsignmentCard from '@/components/production/consignment/ConsignmentCard'

import { mixinRegisterListPage } from '@/mixins/views/Production/Order/mixinRegisterListPage'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinModalHandler } from '@/mixins/modal/MixinModalHandler'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import VButton from '@/components/simple/button/VButton.vue'
import { ConsignmentTable } from '@/entities/order'
import ModalConsignmentSettingsTable from '@/components/ui/Modal/Production/Consignment/ModalConsignmentSettingsTable.vue'
import { showAlertError, showAlertWarning } from '@/utils/store/alert'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'
import { downloadLink } from '@/shared/lib/browser/download-link'
import { getConsignmentStatusIdByRouteName } from '@/utils/order/consignment'
import router from '@/router'

export default {
  components: {
    ModalConsignmentSettingsTable,
    ConsignmentTable,
    VButton,
    AppPagination,
    ConsignmentCard
  },

  mixins: [
    mixinRegisterListPage,
    mixinModal,
    mixinModalHandler
  ],
  props: {
    stageLine: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    loadingTable: false,
    sortTable: '-id',
    isLoadingExcel: false
  }),

  computed: {
    ...mapState('registerPage', {
      viewType: 'consignmentView'
    }),
    ...mapState('consignment', {
      consignmentList: 'consignmentList',
      isLoadingConsignmentList: 'isLoadingConsignmentList',
      pagination: 'pagination'
    }),
    ...mapGetters({
      tableColumnsCurrent: 'consignment/tableColumnsCurrent',
      getQueryParams: 'consignment/getQueryParams',
      getSort: 'consignment/getSort'
    }),
    ...mapGetters('permission', {
      permissionExportXslConsignment: 'permissionExportXslConsignment'
    })
  },

  methods: {
    ...mapMutations('consignment', {
      REMOVE_CONSIGNMENT: 'REMOVE_CONSIGNMENT',
      UPDATE_CONSIGNMENT: 'UPDATE_CONSIGNMENT',
      CLEAR_STATE: 'CLEAR_STATE',
      SET_SORT: 'SET_SORT',
      RESET_SORT: 'RESET_SORT'
    }),
    ...mapMutations('warehouse', ['COUNT_CALC_BY_ARTICLE']),
    ...mapActions('consignment', {
      fetchConsignmentListData: 'fetchConsignmentListData',
      loadPage: 'loadPage',
      updateConsignment: 'updateConsignment'
    }),
    ...mapMutations('registerPage', {
      SET_CONSIGNMENT_VIEW: 'SET_CONSIGNMENT_VIEW'
    }),

    async consignmentAddDirtyWeightHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
    },

    async consignmentGoStageNextHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
      await this.fetchConsignmentListData()
    },

    async consignmentGoSplitPrintHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
      await this.fetchConsignmentListData()
    },

    async consignmentAddExecutorHandler (data) {
      this.UPDATE_CONSIGNMENT(data)
    },

    async consignmentRemoveHandler (consignment) {
      this.REMOVE_CONSIGNMENT(consignment.id)
      this.COUNT_CALC_BY_ARTICLE(consignment)
    },

    async consignmentUpdateHandler (data, handlers) {
      const callback = async () => {
        await this.updateConsignment(data)
      }

      await this.modalHandler(handlers, callback)
    },

    changeViewType (type = 'cards') {
      this.SET_CONSIGNMENT_VIEW(type)
    },

    async sortedHandler (data) {
      data.sortDesc = !data.sortDesc
      this.SET_SORT((data.sortDesc ? '-' : '') + (data.sort || data.name || 'id'))
      await this.fetchConsignmentListData()
    },

    async resetSortHandler () {
      this.RESET_SORT()
      this.SET_SORT('-id')
      await this.fetchConsignmentListData()
    },

    async getExcelFile () {
      try {
        this.isLoadingExcel = true
        showAlertWarning('Началась подготовка отчета, пожалуйста, подождите')

        const params = {
          status_line: getConsignmentStatusIdByRouteName(router.currentRoute.name),
          sort: this.getSort ?? '-id',
          ...this.getQueryParams
        }

        const response = await ConsignmentController.getExport(params)
        if (response?.status !== 200) return

        console.log(response.data)

        await this.downloadFile(response?.data)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.isLoadingExcel = false
      }
    },

    async downloadFile (data) {
      const url = data.export_path
      const name = data.export_path.split('/').at(-1)

      const response = await fetch(url, {
        mode: 'cors'
      })

      const blob = await response.blob()
      const blobURL = URL.createObjectURL(blob)

      downloadLink(blobURL, name)
    }
  }
}
</script>
